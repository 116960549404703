/*eslint-disable */
// import userYjsdocsHistoryModel from '../../Model/userYjsdocsHistory'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let userYjsdocsHistorys = {
  /**
   * userYjsDocsHistoryList
   */
  async userYjsDocsHistoryList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_yjsdocs_history_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userYjsDocsHistoryList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * userYjsDocsHistoryView
   */
  async userYjsDocsHistoryView (context, userYjsDocsHistoryId) {
    try {      
      let post_data = new FormData();
      post_data.append('user_yjs_docs_history_id', userYjsDocsHistoryId);
      return await request.curl(context, "user_yjsdocs_history_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at userYjsDocsHistoryView() and Exception:",err.message)
    }    
  },

  /**
   * userYjsDocsHistoryAdd
   */
  async userYjsDocsHistoryAdd (context, userYjsDocsHistoryObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userYjsDocsHistoryObj) {
        post_data.append(key, userYjsDocsHistoryObj[key]);
      }

      return await request.curl(context, "user_yjsdocs_history_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userYjsDocsHistoryAdd() and Exception:',err.message)
    }
  },

  /**
   * userYjsDocsHistoryEdit
   */
  async userYjsDocsHistoryEdit (context, userYjsDocsHistoryObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userYjsDocsHistoryObj) {
        post_data.append(key, userYjsDocsHistoryObj[key]);
      }

      return await request.curl(context, "user_yjsdocs_history_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_yjsdocs_history_edit() and Exception:',err.message)
    }
  },

  /**
   * userYjsDocsHistoryDelete
   */
  async userYjsDocsHistoryDelete (context, userYjsDocsHistoryId) {
    try{
      let post_data = new FormData();
      
      post_data.append('user_yjs_docs_history_id', userYjsDocsHistoryId);

      return await request.curl(context, "user_yjsdocs_history_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at userYjsDocsHistoryDelete() and Exception:',err.message)
    }
  }
}

export {
  userYjsdocsHistorys
}

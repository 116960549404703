<template>
  <div id="user_profile">
    <b-row>
      <b-col sm="12">
        <iq-card class="iq-card" >
          <div class="iq-card-body profile-page p-0">
            <div class="profile-header">
              <div class="cover-container" style="overflow:hidden;">
                <img v-if="vmCoverPic" :src="vmCoverPic" class="rounded img-fluid" style="object-fit: cover; height:250px; width: 100%;">
                <img v-else :src="vmBannerImage" class="rounded img-fluid" style="object-fit: cover; height:250px; width: 100%;">
              </div>
              <div class="user-detail text-center mb-3">
                <div class="profile-img" >
                  <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-img" class="img-fluid rounded-circle" style="object-fit: cover; width:100px; height:100px;" />
                  <b-avatar v-else variant="primary" class="avatar-100 rounded" :text="getFirstLetterOfAString(userObj.user_name)" style="border-radius: 100%!important;"></b-avatar>
                  <ul class="header-nav d-flex flex-wrap p-0 m-0" style="top:10px" v-if="cvUserId === userData.user_id ">
                    <li>
                      <a @click="goToUserProfileEdit()"><i class="ri-pencil-line" title="Edit Profile"></i></a>
                    </li>
                    <li>
                      <a @click="deleteAccount()"><i class="ri-delete-bin-line" title="Delete Account"></i></a>
                    </li>
                    <li>
                      <a @click="my_billing_history()"><i class="ri-currency-line" title="View Billing Transaction History"></i></a>
                    </li>
                    <li>
                      <a  v-if="lor" @click="viewLor()"><i class="ri-file-paper-line" title="View LOR (Letter of Recommendation)"></i></a>
                    </li>
                    <li>
                      <a @click="generateLor()"><i class="ri-file-add-line" title="Generate LOR (Letter of Recommendation)"></i></a>
                    </li>
                  </ul>
                </div>
                <div class="profile-detail">
                  <h3 class="details_flag">
                    {{userObj.user_name}}
                  </h3>
                  <!-- Show this to other users to Flag Inappropriate profile -->
                  <div class="iq-card-header-toolbar profileMoreBtn w-full" v-if="cvUserId !== userData.user_id">
                    <b-dropdown id="dropdownMenuButton40" variant="none" menu-class="p-0 mr-0">
                      <template v-slot:button-content>
                        <b-link href="javascript:void(0);">
                          <i class="mr-2 p-0 fa fa-flag"></i>
                        </b-link>
                      </template>
                      <a class="dropdown-item p-3" @click="ugcx_add(userObj.user_id, 'PROFILE')" href="javascript:void(0)">
                        <div class="d-flex align-items-top">
                          <div class="icon font-size-20">
                            <i class="fa-solid fa-eye-slash"></i>
                          </div>
                          <div class="data ml-2">
                            <h6>Hide Profile</h6>
                          </div>
                        </div>
                      </a>

                      <a class="dropdown-item p-3" @click="showModelReportFn(userObj.user_id, 'PROFILE')" href="javascript:void(0)">
                        <div class="d-flex align-items-top">
                          <div class="icon font-size-20">
                            <i class="fa-solid fa-circle-exclamation"></i>
                          </div>
                          <div class="data ml-2">
                            <h6>Report Profile</h6>
                          </div>
                        </div>
                      </a>
                    </b-dropdown>

                    <b-badge class="border mr-2 mb-2 font-weight-normal" variant="primary" style="font-size: 14px; font-weight: bold !important;" @click="MeshChatInviteSend" title="Send Invite">
                      Message&nbsp;<i class="ri-message-3-line pointer"></i>
                    </b-badge>
                  </div><!-- Show this to other users to Flag Inappropriate profile -->

                  <!-- Show Profile Menu Items -->
                  <div class="menu_icon iq-card-header-toolbar profileMoreBtn" v-if="cvUserId == userData.user_id">
                    <b-dropdown id="dropdownMenuButton40" variant="none" menu-class="p-0 mr-0">
                    <template v-slot:button-content>
                      <b-link>
                        <i class="ml-0 p-0 mt-1 fa-solid fa-ellipsis-vertical primary-color"></i>
                      </b-link>
                    </template>
                    <a class="dropdown-item p-3 pointer" @click="goToUserProfileEdit()" title="Edit Profile">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-pencil-line"></i>
                        </div>
                        <div class="data ml-2">
                          <h6>Edit Profile</h6>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer">
                      <div class="d-flex align-items-top share_icon">
                        <div class="align-items-top text-align-left">
                          <SocialMediaShare :propShareUrl="VUE_APP_SERVER_URL + '/public/gid/' + cvUserId"></SocialMediaShare>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer" @click="goToResumeBuilder()" title="Build Your Resume">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-file-paper-line"></i>
                        </div>
                        <div class="data ml-2">
                          <h6>Resume Builder<sup>Beta</sup></h6>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer" @click="deleteAccount()" title="Delete Account">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-delete-bin-line font-size-20"></i>
                        </div>
                        <div class="data ml-2">
                          <h6>Delete Account</h6>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer" @click="my_billing_history()" title="View Billing Transaction History">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-currency-line"></i>
                        </div>
                        <div class="data ml-2">
                          <h6> Billing History</h6>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer" v-if="lor" @click="viewLor()" title="View LOR (Letter of Recommendation)">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-file-paper-line font-size-20"></i>
                        </div>
                        <div class="data ml-2">
                          <h6>View LOR</h6>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item p-3 pointer" @click="generateLor()" title="Generate LOR (Letter of Recommendation)">
                      <div class="d-flex align-items-top">
                        <div class="icon font-size-20">
                          <i style="position: relative;top: -4px;" class="ri-file-add-line font-size-20"></i>
                        </div>
                        <div class="data ml-2">
                          <h6>Generate LOR</h6>
                        </div>
                      </div>
                    </a>
                    </b-dropdown>
                  </div>
                  <!-- Show Profile Menu Items -->
                </div>
              </div>

              <!-- TOBEIMPLEMENTED -->
              <!-- <div class="profile-info d-flex align-items-center justify-content-between position-relative">
                <div class="social-info dontShowMobileHideDesktop">
                  <ul class="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                    <li v-for="(i,index) in socialInfo" :key="index" class="text-center pl-3">
                      <h6>
                        {{i.name}}
                      </h6>
                      <p class="mb-0">
                        {{i.value}}
                      </p>
                    </li>
                    <li v-if="userData.user_id !== $route.params.user_id &&  userObj.user_role==='USERROLE11112'" class="ml-3 follow_unfollow_laptop">
                      <button class="px-3 py-2 btn btn-primary rounded" @click="followUser(userFollowObj.followed_by_curr_user)">
                        <span>{{userFollowObj.followed_by_curr_user===0?'Follow':'Unfollow'}}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- TOBEIMPLEMENTED -->
            </div>
          </div>
        </iq-card>

        <iq-card class="iq-card">
          <div class="iq-card-body p-0 showFlexListDiv">
            <div class="user-tabing showFlexList">
              <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                <div  class="flex-fill p-0"  @click="loadTabData(false, 'about')">
                  <tab-nav-items class="flex-fill p-0" :active="aboutActive" id="pills-activity-tab" href="#profile-activity" ariaControls="pills-profile" role="tab" :ariaSelected="aboutActive" title="My profile" />
                </div>
                <div  class="flex-fill p-0" @click="loadTabData(false, 'detail')">
                  <tab-nav-items class="flex-fill p-0" :active="detailActive" id="pills-feed-tab" href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="detailActive" title="My Posts"/>
                </div>
                <div  class="flex-fill p-0"  @click="loadTabData(false, 'event')">
                  <tab-nav-items class="flex-fill p-0"  :active="eventActive" id="event-tab" href="#events" ariaControls="event" role="tab" :ariaSelected="eventActive" title="My Activities" />
                </div>
              </tab-nav>
            </div>
          </div>
        </iq-card>
      </b-col>

      <b-col class="col-12">
        <div class="tab-content">
          <tab-content-item :active="detailActive" id="profile-feed" aria-labelled-by="pills-feed-tab">
            <b-row>
              <b-col class="col-lg-12">
                <div id="post-modal-data" v-if="cvUserId">
                  <div v-if="userData.user_id === cvUserId">
                    <PostAdd :propPostType="5" :propModuleObjId="userData.user_id"/>
                  </div>
                  <div id="post-modal-data">
                    <PostList :propPostType="5" :propModuleId="cvUserId"/>
                  </div>
                </div>
              </b-col>
            </b-row>
          </tab-content-item>

          <tab-content-item :active="aboutActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <div class="row">
              <!-- Left Hand Side of My Profile -->
              <b-col class="col-12 col-sm-12 col-md-4">
                <div class="row m-0">
                  <!-- Show Personal Info on Users Profile View Screen -->
                  <PersonalInfo v-if="(cvUserId === userData.user_id || userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') " :propUserId="cvUserId" propDisplayHeader="1" :propProfileComplete="propProfileComplete"/>
                  <!-- Show Personal Info on Users Profile View Screen -->

                  <div class="iq-card col-12 col-lg-12" v-if="cvUserId === userData.user_id">
                    <div class="iq-card-header d-flex justify-content-between pl-2">
                      <div class="iq-header-title w-100">
                        <h4 class="card-title mt-1">
                          Websites & Social Links
                          <span class="educationAddIcon" v-if="cvUserId === userData.user_id" @click="websiteAndLinks()">
                            <i class="fa-solid ri-pencil-line"></i>
                          </span>
                        </h4>
                      </div>
                    </div>
                    <hr class="m-0"/>
                    <div class="iq-card-body pointer" v-if="vmSocialLinks && Object.keys(vmSocialLinks).length <= 0">
                      <span @click="websiteAndLinks()">Add Links (+)</span>
                    </div>
                    <div class="iq-card-body v-else">
                      <div class="mb-4" v-if="vmSocialLinks">
                        <div class="row" v-for="(item, index) in vmSocialLinks" :key="index+'1'">
                          <div class="col-3 capitalize pr-0 mb-3">
                            <h6 >{{index}}</h6>
                          </div>
                          <div class="col-9" :key="index+'1'">
                            <p class="mb-0">
                              <a :href="item" target="_blank">{{item}}</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-12" v-if="cvUserId !== userData.user_id">
                    <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
                    <CareerHomeList/>
                  </div>
                  <!-- Webiste and Social End -->
                </div>
              </b-col><!-- Left Hand Side of My Profile -->

              <!-- Right Hand Side of My Profile -->
              <div class="col-12 col-sm-12 col-md-8" v-if="userObj.user_id && aboutActive">
                <!-- About Myself View Only Description -->
                <About :propUserObj="userObj" :propShowReadMore="false" :propShowOnlyContactDetail='false'/><!-- About Myself View Only Description -->

                <!-- Education View -->
                <div class="col-sm-12 col-lg-12 p-0">
                  <div class="iq-card">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title w-100">
                          <h4 class="card-title">Education&nbsp;&nbsp;
                            <span class="educationAddIcon" v-if="cvUserId === userData.user_id" @click="showAddEducation()"><i class="fa-solid fa-circle-plus"></i></span>
                          </h4>
                        </div>
                    </div>
                    <hr class="m-0"/>
                    <div class="iq-card-body">
                        <p class="mb-4" v-if="user_profile && user_profile.education && user_profile.education.length">
                          <Education :propUserView="cvUserId === userData.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" @emitUserProfileEducation="addEducation">
                          </Education>
                        </p>
                        <p v-else class="mb-4">
                          Info not available
                        </p>
                    </div>
                  </div>
                </div><!-- Education View -->

                <!-- Extracurricular View -->
                <div class="col-sm-12 col-lg-12 p-0" v-if="VUE_APP_ENV == 'prod_donot_show_as_class_school_missing'">
                  <div class="iq-card">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title w-100">
                          <h4 class="card-title">Extra Curricular&nbsp;&nbsp;
                            <span class="educationAddIcon" v-if="cvUserId === userData.user_id" @click="showAddExtraCaricullar()"><i class="fa-solid fa-circle-plus"></i></span>
                          </h4>
                        </div>
                    </div>
                    <hr class="m-0"/>
                    <div class="iq-card-body">
                        <p class="mb-4" v-if="user_profile && user_profile.education && user_profile.education.length">
                          <ExtraCurricular :propUserView="cvUserId === userData.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" @emitUserProfileEducation="addExtraCurricular"></ExtraCurricular>
                        </p>
                        <p v-else class="mb-4">
                          Info not available
                        </p>
                    </div>
                  </div>
                </div><!-- Extracurricular View -->

                <!-- User Program Test View Start-->
                <div class="col-sm-12 col-lg-12 p-0">
                  <div class="iq-card">
                    <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title w-100">
                          <h4 class="card-title">Programs and Test&nbsp;&nbsp;
                            <span class="educationAddIcon" @click="showProgramModal()"><i class="fa-solid fa-circle-plus"></i></span>
                          </h4>
                        </div>
                    </div>
                    <hr class="m-0"/>
                    <div class="iq-card-body">
                        <div v-if="userProgramTest && Object.keys(userProgramTest).length" class="">
                          <div class="educationCard pt-2" v-for="(item, index) in userProgramTest" :key="index+'program'" >
                            <ProgramTest :propProgramDetail="item" :propOpenedInModal="false">
                            </ProgramTest>
                          </div>
                        </div>
                        <p v-else class="mb-4">
                          Info not available
                        </p>
                    </div>
                  </div>
                </div><!-- User Program Test View End-->
              </div><!-- Right Hand Side of My Profile -->
            </div>
          </tab-content-item>

          <tab-content-item :active="eventActive" v-if="cvUserId" id="events" aria-labelled-by="event-tab">
            <EventList :propShowUserEvent="true" :propUserId="cvUserId"/>
          </tab-content-item>

          <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
            <iq-card>
              <template v-slot:body>
                <h2>
                  Photos
                </h2>
                <tab-nav :pills="true" id="pills" class="nav nav-pills d-flex align-items-center justify-content-left friend-list-items p-0 mb-2">
                  <tab-nav-items liClass=" " :active="true" id="pills-photo-you-tab" href="#photo-you" ariaControls="pills-photo-you" role="tab" :ariaSelected="true" title="Photo of you" />
                  <tab-nav-items liClass=" " :active="false" id="pills-your-photo-tab" href="#your-photo" ariaControls="pills-your-photo" role="tab" :ariaSelected="false" title="your photo" />
                </tab-nav>
                <div class="tab-content">
                  <tab-content-item :active="true" id="photo-you" aria-labelled-by="pills-photo-you-tab">
                    <b-row>
                      <b-col v-for="(item,index) in profileImages" :key="index" md="6" lg="3" class="mb-3">
                        <div class="user-images position-relative overflow-hidden">
                          <a href="#">
                            <img :src="item.img" class="img-fluid rounded" alt="Responsive image">
                          </a>
                          <div class="image-hover-data">
                            <div class="product-elements-icon">
                              <ul class="d-flex align-items-center m-0 p-0 list-inline">
                                <li v-for="(item,index1) in item.otherInfo" :key="index1">
                                  <a href="#" class="pr-3 text-white"> {{item.value}} <i :class="item.class"></i> </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <a href="#" class="image-edit-btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit or Remove"><i class="ri-edit-2-fill"></i></a>
                        </div>
                      </b-col>
                    </b-row>
                  </tab-content-item>
                </div>
              </template>
            </iq-card>
          </tab-content-item>
        </div>
      </b-col>
    </b-row>

    <template v-if="addEducationShow">
      <Education :propUserView="cvUserId === userData.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
    </template>

    <template v-if="addExtraCurricularShow">
      <ExtraCurricular :propUserView="cvUserId === userData.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" :propExtraCaricullarShow='addExtraCurricularShow'  @emitUserProfileEducation="addExtraCurricular" @emitCloseExtracurricularModal="closeExtraCurricularModal"></ExtraCurricular>
    </template>

    <b-modal v-if="(reportModuleObjId && reportModuleName)"  v-model="showModelReport" scrollable size="md" centered title="Report " hide-footer  no-close-on-backdrop no-close-on-esc  >
      <ReportIssue  @emitCloseadminReportIssueAddModal="closeReportAddModal" :propModuleId="reportModuleObjId" :propModuleType="reportModuleName" :propOpenedInModal="true">
      </ReportIssue>
    </b-modal>
    <b-modal
      v-model="showModalWebSite"
      scrollable
      :title="cvAddModalHeader"
      size="lg"
      :hide-footer="true"
      no-close-on-esc
      >
      <websiteAndLinks :propOpenedInModal="showWesiteAndLinkModal" @emitcloseWebsite_Links="closeWebsite_Links" :propSocialLinks = "propSocialLinks" />
    </b-modal>
    <template v-if="showLOR">
      <b-modal v-model="showLOR" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true" :title="cvLetterModalHeader">
        <DownLoadAsPDF :propText="lor"/>
        <div class="mt-4">
          Disclaimer: This letter of recommendation has been generated as a template based on the information provided by the user, including profile details, education history, entrance exam and test scores, participation in events and activities, quizzes, and indicated interests. It is important to note that this template is not intended to serve as the final version of the letter of recommendation. <br><br>
          The template provided here is a product of automated content generation and is offered solely as a starting point for crafting a personalized letter of recommendation. Its contents have not been reviewed or endorsed by any school counselor, participating teacher, or competent authority at this time. <br><br>
          Before finalizing and submitting this letter of recommendation, it is strongly advised that it be thoroughly reviewed, revised, and endorsed by a qualified school counselor, participating teacher, or another appropriate competent authority. Any modifications and additions should be made to ensure that the content accurately reflects the student's achievements, character, and potential. <br><br>
          Ultimately, the responsibility lies with the user to ensure that the final version of the letter of recommendation aligns with the student's unique qualities and accomplishments. This template is not a substitute for professional judgment and individualized evaluation by educational professionals.
        </div>
      </b-modal>
    </template>

    <ProgramTest :propGideUser="profileUserId" v-if="showProgramTestModal" :propProgramDetail="programEditObj" :propOpenedInModal="showProgramTestModal" @emitCloseProgramModal="closeProgramModal">
    </ProgramTest>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <AlertBox :key="showDeleteConfirmAlertBox" v-if="showDeleteConfirmAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showDeleteConfirmAlertBox=false" @emitConfirmAlertModel="deleteAccountCompleted()"/>
  </div>
</template>

<script>
import { socialvue } from "../../../../config/pluginInit"
import About from "../../Profile/User/ProfileFriends/About.vue"
import { User } from "../../../../FackApi/api/user.js"
import { Images } from "../../../../FackApi/api/image"
import { Follows } from "../../../../FackApi/api/follow"
import { events } from "../../../../FackApi/api/events"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { SocialLinks } from "../../../../FackApi/api/socialLink"
import EventList from "../../Event/EventList.vue"
import TabContentItem from "../../../../components/socialvue/tab/tab-content-item.vue"
import Education from "../../../../components/UserProfile/Education.vue"
import ProgramTest from "../../../../components/UserProfile/ProgramTest.vue"
import { UserContentExcludes } from "../../../../FackApi/api/UserContentExclude.js"
import ReportIssue from "../../../../components/ReportIssue.vue"
import PostList from "../../Post/PostList.vue"
import PostAdd from "../../Post/PostAdd.vue"
import AlertBox from "../../../../components/AlertBox.vue"
import PersonalInfo from "../../../../components/UserProfile/PersonalInfo.vue"
import websiteAndLinks from "../../../../components/websiteAndLinks.vue"
import { lor } from "../../../../FackApi/api/docs/lor"
import DownLoadAsPDF from "../../../../components/DownloadAsPDF.vue"
import EventHomeList from "../../Event/EventHomeList.vue"
import CareerHomeList from "../../ListPages/Career/CareerHomeList.vue"
import SideBarMenu from "../../../../Utils/sidebarMenu.js"
import Utility from "../../../../Utils/utility"
import { MeshApi } from "../../../../FackApi/api/MeshApi"
import SocialMediaShare from "../../../../components/SocialMediaShare.vue"
import ExtraCurricular from "../../../../components/UserProfile/ExtraCurricular.vue"

export default {
  name: "Profile",
  components: {
    About,
    EventList,
    TabContentItem,
    Education,
    ProgramTest,
    ReportIssue,
    PostList,
    PostAdd,
    AlertBox,
    PersonalInfo,
    websiteAndLinks,
    DownLoadAsPDF,
    EventHomeList,
    CareerHomeList,
    SocialMediaShare,
    ExtraCurricular
  },
  data () {
    return {
      lifeEvent: [],
      friends: [],
      socialInfo: [
        {
          name: "Post",
          value: 10
        }
      ],
      editMode: false,
      cvUniversityEditHeader: "University Edit",
      showModalUniversityEdit: false,
      showWesiteAndLinkModal: false,
      photos: [],
      profileImages: [],
      userObj: {},
      userFollowObj: {},
      vmFollowData: Object.assign({}, this.initFollowData()),
      vmProfilePic: "",
      vmCoverPic: "",
      followStatusOfCurrUser: 0,
      followerApiFire: false,
      feedActive: false,
      detailActive: false,
      aboutActive: true,
      followerActive: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User Profile",
      vmSocialLinks: {},
      vmBannerImage: "/img/background.jpg",
      eventActive: false,
      user_profile: null,
      addEducationShow: false,
      addExtraCurricularShow: false,
      programShow: false,
      extraCurricularListObj: {},
      userProgramTest: {},
      programEditObj: {},
      showModelReport: false,
      reportModuleObjId: null,
      reportModuleName: "PROFILE",
      // parentList: [],
      propSocialLinks: null,
      showDeleteConfirmAlertBox: false,
      alertTitle: "",
      alertDesc: "",
      okText: "Delete",
      cancelText: "Not now",
      showProgramTestModal: false,
      showModalWebSite: false,
      cvAddModalHeader: "Website And Social Links",
      showWebSiteAndLink: true,
      lor: "",
      showLOR: false,
      cvLetterModalHeader: "Letter Of Recommendation",
      cvButtonGenerate: "Generate LOR",
      cvButtonView: "View LOR",
      letter: "",
      propProfileComplete: null,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      o2o_participant_id: null,
      VUE_APP_SERVER_URL: process.env.VUE_APP_SERVER_URL,
      profileUserId: {
        user_id: this.$route.params.user_id
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    cvUserId () {
      return this.$route.params.user_id
    }
  },
  mounted () {
    if (this.cvUserId === this.userData.user_id) {
      this.editMode = true
    }

    document.addEventListener("click", this.closeSearch, true)
    this.loadRequiredData()
  },
  methods: {
    /**
     * MeshChatInviteSend
     */
    async MeshChatInviteSend () {
      /*eslint-disable */
      let participant_id = this.o2o_participant_id
      let group_id = Utility.getUniqueId(this.userData.user_id, participant_id);

      let participant_data = [];
      participant_data[0] = {};
      participant_data[0]['is_Admin'] = 1;
      participant_data[0]['participant_id'] = this.userData.user_id;

      participant_data[1] = {};
      participant_data[1]['is_Admin'] = 1;
      participant_data[1]['participant_id'] = participant_id;

      const meshGrpAddObj = {
        chat_user_id: this.userData.user_id,
        group_id: group_id,
        type: "0",
        deleted: 2,
        invite_page: true,
        participants: JSON.stringify(participant_data)
      }

      const meshGrpAddResp = await MeshApi.MeshChatInviteSend(this, meshGrpAddObj)
      if (meshGrpAddResp.resp_status) {
        this.toastMsg = "Invite Sent Successfully"
        this.toastVariant = "success"
        this.showToast = true
      }
      else {
        this.toastMsg = meshGrpAddResp.resp_code
        this.toastVariant = "danger"
        this.showToast = true
      }
    },
    /**
     * websiteAndLinks
     */
    websiteAndLinks () {
      this.showModalWebSite = true
    },
    /**
     * generateViewLor
     */
    async generateLor () {
      const lorResp = await lor.user_profile_generate_lor(this)
      this.toastMsg = lorResp.resp_data
      this.toastTitle = "Message"
      this.toastVariant = "success"
      this.showToast = true
    },
    /**
     * viewLor
     */
    async viewLor () {
      this.showLOR = true
    },
    /**
     * my_billing_history
     */
    my_billing_history () {
      this.$router.push(`/my_billing_history/${this.userData.user_id}`)
    },
    /**
     *
     */
    closeWebsite_Links () {
      console.log("Emitted !")
      this.showWesiteAndLinkModal = false
      this.showModalWebSite = false
      this.socialLinkView()
    },
    /**
     * closeProgramModal
     */
    closeProgramModal (event) {
      if (event) {
        this.userProgramTest = JSON.parse(event.resp_data.user_program_test)
      }
      this.showProgramTestModal = false
    },
    /**
     * emitOpenAboutTab
     */
    emitOpenAboutTab () {
      this.loadTabData(false, "about")
    },
    /*
     * addEducation
     */
    async addEducation (payload) {
      if (!this.user_profile) {
        this.user_profile = {}
      }

      if (!this.user_profile.education) {
        this.user_profile.education = []
      }

      const educationIndex = this.user_profile.education.findIndex(edu => edu.id === payload.newEducation.id)
      if (educationIndex >= 0) {
        this.user_profile.education[educationIndex] = payload.newEducation
      }
      else {
        this.user_profile.education.unshift(payload.newEducation)
      }

      this.user_profile.user_id = this.userData.user_id
      this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      this.addEducationShow = false
      this.userView()
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.addEducationShow = false
    },
    /*
     * addExtraCurricular
     */
    async addExtraCurricular (payload) {
      this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      this.addExtraCurricularShow = false
      this.userView()
    },
    /**
     * closeEducationModal
     */
    closeExtraCurricularModal () {
      this.addExtraCurricularShow = false
    },
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * eventRSVPList
     */
    async eventRSVPList () {
      const obj = {
        user_id: this.cvUserId,
        curr_user_id: this.cvUserId,
        limit: 4
      }
      const eventRsvpResp = await events.eventRsvpMyEventList(this, obj)
      if (eventRsvpResp.resp_status) {
        this.lifeEvent = eventRsvpResp.resp_data.data
      }
    },
    addPost (post) {
      this.socialPosts.unshift(post)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.userView()
      this.userProfileView()

      this.socialLinkView()
      this.imageGetProfileImages()
      this.eventRSVPList()
    },
    /**
     * UserView
     */
    async userView () {
      try {
        let userViewResp = await User.userView(this, this.cvUserId, this.userData.user_id)

        if (userViewResp && userViewResp.resp_status) {
          this.userObj = userViewResp.resp_data.data

          // this.vmBannerImage = Utility.getBgImageForLetter(this, this.userObj.user_name)
          this.followStatusOfCurrUser = userViewResp.user_follow.followed_by_curr_user
          this.userFollowObj = userViewResp.user_follow

          if (userViewResp.post_count) {
            this.socialInfo[0]["value"] = userViewResp.post_count
          }

          if (userViewResp.user_follow && userViewResp.user_follow.follow_count) {
            this.socialInfo[1].value = userViewResp.user_follow.follow_count
          }
        }
        else {
          if (userViewResp.resp_code === "ERR_USER_BLOCKED_BY_VIEWER") {
            await ApiResponse.responseMessageDisplay(this, userViewResp)

            setTimeout(() => {
              window.location = "/home"
            }, 3000)
          }
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    },
    /**
     * userProfileView
     */
    async userProfileView () {
      let userProfileViewResp = await User.userProfileView(this, this.cvUserId)
      if (userProfileViewResp.resp_status) {
        this.o2o_participant_id = userProfileViewResp.resp_data.data.user_id
        userProfileViewResp.resp_data.user_id = userProfileViewResp.resp_data.data.gide_user_id

        if (typeof userProfileViewResp.resp_data.data.user_profile === "string") {
          userProfileViewResp.resp_data.data.user_profile = JSON.parse(userProfileViewResp.resp_data.data.user_profile)
        }

        this.extraCurricularListObj = userProfileViewResp.resp_data.data.extraCurricularListObj

        // Filter out the commonapp and university specific essays as we do not intend to show them in the user's profile
        Object.keys(this.extraCurricularListObj).forEach((key) => {
          this.extraCurricularListObj[key] = this.extraCurricularListObj[key].filter( e => {
            return e.uexcc_type_key && e.uexcc_type_key != "COMMONAPP_ESSAY" && e.uexcc_type_key != "UNIV_ESSAY" 
          })
        })

        this.user_profile = userProfileViewResp.resp_data.data.user_profile
        this.userProgramTest = JSON.parse(userProfileViewResp.resp_data.data.user_program_test)
        this.user_profile.user_id = userProfileViewResp.resp_data.data.gide_user_id
        this.lor = userProfileViewResp.resp_data.data.user_profile_lor
        this.propProfileComplete = userProfileViewResp.resp_data.data.user_profile_percentage
      }
    },
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
    },
    /**
     * showAddExtraCaricullar
     */
    showAddExtraCaricullar () {
      this.addExtraCurricularShow = true
    },
    /**
     * showAddProgramAndTest
     */
    showProgramModal () {
      this.showProgramTestModal = true
      this.programEditObj = {}
    },
    /**
     * socialLink
     */
    async socialLinkView () {
      try {
        const socilLinkViewResp = await SocialLinks.socialLinkView(this, this.cvUserId)
        if (!socilLinkViewResp.resp_status) {
          return
        }
        if (socilLinkViewResp.resp_data.data.social_links) {
          this.propSocialLinks = socilLinkViewResp.resp_data.data.social_links
          this.vmSocialLinks = JSON.parse(socilLinkViewResp.resp_data.data.social_links)
        }
      }
      catch (error) {
        console.error("Exception occurred at userView() and Exception:", error.message)
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let imageProfileResp = await Images.imageListProfileImages(this, this.cvUserId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * goToUserProfileEdit
     */
    goToUserProfileEdit () {
      try {
        if (this.userObj && Object.keys(this.userObj).length > 0) {
          this.$router.push("/user_edit/" + this.userObj.user_id)
        }
      }
      catch (err) {
        console.error("Exception occurred at goToUserProfileEdit() and Exception:", err.message)
      }
    },
    /**
     * goToResumeBuilder
     */
    goToResumeBuilder () {
      try {
        if (this.userObj && Object.keys(this.userObj).length > 0) {
          this.$router.push("/resume_builder")
        }
      }
      catch (err) {
        console.error("Exception occurred at goToUserProfileEdit() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityEditModal
     */
    closeUniversityEditModal () {
      try {
        this.showModalUniversityEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUniversityEditModal() and Exception:", err.message)
      }
    },
    /**
     *  followUser()
     */
    async followUser (followStatus) {
      try {
        let userId = this.$route.params.user_id
        this.vmFollowData.module_id = userId
        this.vmFollowData.module_name = "USER"
        this.vmFollowData.follow_status = followStatus ? 0 : 1

        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)

        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.userFollowObj.followed_by_curr_user = this.vmFollowData.follow_status
          this.followStatusOfCurrUser = this.vmFollowData.follow_status

          if (followStatus) {
            this.userFollowObj.follow_count--
          }
          else {
            this.userFollowObj.follow_count++
          }
          this.socialInfo[1].value = this.userFollowObj.follow_count
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followUser() and Exception", err.message)
      }
    },
    /**
     * loadFolloweData
     */
    loadTabData (bool, tabType) {
      try {
        this.followerApiFire = bool
        if (tabType === "feed") {
          this.feedActive = true
          this.aboutActive = false
          this.followerActive = false
          this.detailActive = false
          this.eventActive = false
        }
        else if (tabType === "about") {
          this.feedActive = false
          this.aboutActive = true
          this.followerActive = false
          this.detailActive = false
          this.eventActive = false
        }
        else if (tabType === "detail") {
          this.feedActive = false
          this.aboutActive = false
          this.followerActive = false
          this.detailActive = true
          this.eventActive = false
        }
        else if (tabType === "event") {
          this.feedActive = false
          this.aboutActive = false
          this.followerActive = false
          this.detailActive = false
          this.eventActive = true
        }
      }
      catch (err) {
        console.error("Exception occured at loadTabData() and Exception", err.message)
      }
    },
    /**
     * ugcx_add
     */
    async ugcx_add (moduleObjId, moduleName) {
      try {
        let userContentExcludeObj = {}
        userContentExcludeObj.module_obj_id = moduleObjId
        userContentExcludeObj.module_name = moduleName

        let ugcxResp = await UserContentExcludes.userContentExcludeAdd(this, userContentExcludeObj)
        await ApiResponse.responseMessageDisplay(this, ugcxResp)
        setTimeout(() => {
          window.location = "/home"
        }, 3000)
      }
      catch (err) {
        console.error("Exception occurred at ugcx_add() and Exception:", err)
      }
    },
    /**
     * showModelReportFn
     */
    showModelReportFn (moduleObjId, moduleName) {
      this.reportModuleObjId = moduleObjId
      this.reportModuleName = moduleName
      this.showModelReport = true
    },
    /**
     * closeReportAddModal
     */
    closeReportAddModal () {
      this.showModelReport = false
    },
    /**
  * deleteAccount
  */
    deleteAccount () {
      this.showDeleteConfirmAlertBox = true
      this.alertTitle = "Delete Account Confirmation"
      this.alertDesc = "With this action all your data will be deleted. Are you sure you want to delete your account ?"
    },
    /**
  * deleteAccountCompleted
  */
    async deleteAccountCompleted () {
      this.showDeleteConfirmAlertBox = false

      // Fire the Delete Account API & Logout
      let userDeleteResp = await User.userDelete(this, this.userData.user_id)

      if (!userDeleteResp.resp_status) {
        this.toastTitle = "Account Deletion"
        this.toastMsg = "There was some issue deleting your account. Please write to " + process.env.VUE_APP_SUPPORT_EMAIL
        this.showToast = true
        this.toastVariant = "danger"
      }
      else {
        this.toastTitle = "Account Deleted"
        this.toastMsg = "Account Deletion was success. For restoring it back, please send email to hey@gide.ai from your registered email id"
        this.showToast = true
        this.toastVariant = "success"

        setTimeout(() => {
          this.signOut()
        }, 3000)
      }
    }
  },
  watch: {
    /**
     * cvUserId
     */
    async cvUserId () {
      await this.cvUserId
      this.loadRequiredData()
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer{
  cursor: pointer;
}
.browseMoreEvent {
  float: right;
  font-size: 12px;
  color: var(--iq-primary);
  cursor: pointer;
}
.educationAddIcon {
  float: right;
  color: var(--iq-primary);
  cursor: pointer;
}
@media (max-width: 760px){
  .showFlexListDiv .showFlexList .profile-feed-items {
  width: 100%;
  }
}
.profileMoreBtn{
  height: 25px;
}
.details_flag{
  margin: auto !important;
  position: relative;
  padding: 0px 40px;
  width: auto;;
  white-space: normal;
  .more_icon{
    position: absolute !important;
    right: -30px !important;
    top: -5px !important;
  }
}
.text_user_details{
  i{
    color: var(--iq-primary);
  }
}
#user_profile .profile-header .user-detail {
    position: relative;
    bottom: 30px;
    margin-top: -40px;
    height: auto;
}
.cover-container {
    height: auto;
}
@media (max-width: 767px){
  .user-detail{
    .profile-img{
      .header-nav{
        display: none !important;
      }
    }
  }
}
.menu_icon{
  #dropdownMenuButton40__BV_toggle_{
    a{
      display: block;
      width: 20px;
    }
  }
}
</style>

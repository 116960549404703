/*eslint-disable */
// import SpcmModel from '../../Model/Spcm'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let Spcms = {
  /**
   * spcmList
   */
  async spcmList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmList() and Exception:',err.message)
    }
  },

  /**
   * spcmStateList
   */
  async spcmStateList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_state_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmStateList() and Exception:',err.message)
    }
  },    

  /**
   * spcmView
   */
  async spcmView (context, spcmId=null) {
    try {      
      let post_data = new FormData();
      if (spcmId) {
        post_data.append('spcm_id', spcmId);
      }
      return await request.curl(context, "spcm_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcmView() and Exception:",err.message)
    }    
  },

  /**
   * spcmAdd
   */
  async spcmAdd (context, spcmObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmObj) {
        if (spcmObj[key] || typeof spcmObj[key] == "number") {
          post_data.append(key, spcmObj[key]);
        }
      }

      return await request.curl(context, "spcm_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmAdd() and Exception:',err.message)
    }
  },

  /**
   * spcmStudentPublicReg
   */
   async spcmStudentPublicReg (context, spcmObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmObj) {
        if (spcmObj[key] || typeof spcmObj[key] == "number") {
          post_data.append(key, spcmObj[key]);
        }
      }

      return await request.curl(context, "spcm_student_counsellor_public_registration", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmStudentPublicReg() and Exception:',err.message)
    }
  },

  /**
   * spcmEdit
   */
  async spcmEdit (context, spcmObj) {
    try{
      let post_data = new FormData();

      let s3Attachments = []
      for (let key in spcmObj) 
        if( key === 'spcm_contract' && spcmObj.spcm_contract && spcmObj.spcm_contract.length ){
          for (const attachment of spcmObj.spcm_contract) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
         }
       }
       else {
         if (spcmObj[key] || typeof spcmObj[key] == "number") {
           post_data.append(key, spcmObj[key]);
         }
      }
      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

      return await request.curl(context, "spcm_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmEdit() and Exception:',err.message)
    }
  },

  /**
   * spcmDelete
   */
  async spcmDelete (context, spcmId) {
    try{
      let post_data = new FormData();
      
      post_data.append('spcm_id', spcmId);

      return await request.curl(context, "spcm_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcmDelete() and Exception:',err.message)
    }
  },
  /**
   * spcmChangeCounsellorEdit
   * Allots a new counsellor to the user
   */
  async spcmChangeCounsellorEdit (context, userObj) {
    try {
      let post_data = new FormData();

      for (let key in userObj) {
        if (userObj[key] || key == 'history') {
          post_data.append(key, userObj[key]);
        }
      }

      return await request.curl(context, "spcm_change_counsellor_edit", post_data) // spcm_change_counsellor_edit
        .then(async response => {
          return response
        });
    }
    catch (err) {
      console.error('Exception occurred at spcmChangeCounsellorEdit() and Exception:',err.message)
    }
  },

  /**
    * Student Counselor Team List
    */
  async StudentCounselorTeamList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_student_counselor_team_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at StudentCounselorTeamList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

 /**
   * spcmCategoryList
   */
  async spcmCategoryList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_category_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmCategoryList() and Exception:',err.message)
    }
  },

}

export {
  Spcms
}

<template>
  <b-container fluid  class="p-0">
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
            <template v-slot:headerTitle v-if="!propShowUserEvent">
              <h4 class="card-title py-3">
                <i class="fa-solid fa-calendar" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
                Profile Building Activities & Events
                <span class="btn_in_header">
                  <span class="pull-right mb-2" v-if='userPermission("event_add")'>
                    <b-button variant="primary" @click="eventAdd()">Add (+)</b-button>
                  </span>
                  <span class="pull-right mr-2 mb-2" v-if="userPermission('event_list_dashboard')">
                    <b-button variant="primary" @click="goToEventDashboard">Dashboard</b-button>
                  </span>
              </span>
              </h4>
            </template>
        </iq-card>
        <iq-card>
          <b-col md="12" class="p-0">
            <iq-card class="iq-card" style="width:100%;" v-if="!propShowUserEvent" >
              <div class="iq-card-body p-0 showFlexListDiv">
                <div class="user-tabing showFlexList">
                  <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                    <div  class="flex-fill p-0" @click="switchTabs('allEvent')">
                      <tab-nav-items class="flex-fill p-0" :active="allEventActive" id="pills-all-event-tab" href="#profile-all-event" ariaControls="pills-all-event" role="tab" :ariaSelected="allEventActive" title="Upcoming Events" />
                    </div>
                    <div  class="flex-fill p-0" @click="switchTabs('pastEvent')" >
                      <tab-nav-items  class="flex-fill p-0" :active="pastEventActive" id="pills-my-event-tab"  href="#profile-my-event"  ariaControls="pills-my-event" role="tab" :ariaSelected="pastEventActive" :title="`Past Events`" />
                    </div>
                    <div  class="flex-fill p-0" @click="switchTabs('myEvent')" >
                      <tab-nav-items  class="flex-fill p-0" :active="myEventActive" id="pills-my-event-tab"  href="#profile-my-event"  ariaControls="pills-my-event" role="tab" :ariaSelected="myEventActive" :title="`My Events`" />
                    </div>
                  </tab-nav>
                </div>
              </div>
            </iq-card>
          </b-col>
        </iq-card>
        <iq-card>
          <b-row class="w-100 p-0 mb-2" style="margin:auto">
            <b-col size="12" class="p-0">
              <div class="rounded p-2 mb-1 bg-white" >
                <b-form-input v-model="vmEventSearch" placeholder="Search" @input="searchEvent(vmEventSearch)">
                </b-form-input>
              </div>
            </b-col>
          </b-row>
        </iq-card>
          <template v-if="eventListArr && eventListArr.length">
          <div class="row mobile_row_size w-100 m-0">
            <b-col v-for="(eve,index) in eventListArr" :key="index+'A'" class="mobileNoPadding col-12 col-sm-11 col-md-6 col-lg-6 col-xl-4 col-xxl-3
            ">
              <!-- @todo The Below if has to be removed after changes to backend API. Get Role Specific Event -->
              <iq-card class="rounded iq-card-block iq-card-stretch" :class="pastDateCheck(eve.event_end_date) ? 'pastDate' : ''">
                <div class="event-images">
                  <a @click="goToEveView(eve.event_id)" style="cursor: pointer;">
                    <img style="" v-if="eveImgObj && eveImgObj[eve.event_id] && eveImgObj[eve.event_id]['cover_pic']" :src="eveImgObj[eve.event_id]['cover_pic']['image_thumbnail']" class="img-fluid image image_eventpost" alt="">
                    <div v-else class="image" style="display: flex; text-align: center; background-color: #EEEEEE; border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                      <span style="margin: auto; font-size: 80px;">{{getFirstLetterOfAString(eve.event_name)}}</span>
                    </div>
                  </a>
                </div>
                <div class="row pl-3 pr-3">
                  <div class="col-4 col-sm-5 col-md-4 col-lg-4" >
                    <b-badge
                    class="border mr-2 mb-2 font-weight-normal"
                    variant="primary"
                    style="font-size: 11px; font-weight: bold !important;"
                  >
                    {{eve.event_master_type == 'Gide+' ? 'Gide Plus' : 'For All'}}
                  </b-badge>
                  </div>
                  <div class="col-8 col-sm-7 col-md-8 col-lg-8 rating_comments">
                    <div v-for="index in 5" :key="index" class="rating ratingInput">
                      <label v-if="index <= eve.rating" class="selected">☆</label>
                        <label v-else class="unselected">☆</label>
                      </div>
                  </div>
                </div>
                <b-card-body class="iq-card-body rsvpCardBody">
                  <div class="webKitBox">
                    <div class="date-of-event">
                      <span>{{getFormattedDate(eve.event_start_date, 'month')}}</span>
                      <h5>
                        {{getFormattedDate(eve.event_start_date, 'date')}}
                      </h5>
                    </div>
                    <div class="events-detail ml-3" @click="goToEveView(eve.event_id)" style="cursor: pointer;">
                      <h5 class="twoLineOnly pr-3">
                        {{eve.event_name}}
                      </h5>
                      <small>
                        Start date: {{ eve.event_start_date|dateFormatDDMMYYYY }}&nbsp;/&nbsp;
                        End date: {{ eve.event_end_date|dateFormatDDMMYYYY }}
                      </small><br>
                      <span v-if="eve.event_allowed_role">
                        <b-badge class="mr-2" v-for="event_allowed_role in eve.event_allowed_role.split(',')" :key="event_allowed_role+eve.event_id">
                          {{userRolesJson[event_allowed_role]}}
                        </b-badge>
                      </span>
                      <span v-if="eve.event_total_regs" class="ml-4">
                        <i class="fa fa-user text-primary pr-2"></i>Registered Users: <span class="primary-color">{{ eve.event_total_regs }}</span>
                      </span>
                      <p class="threeLineOnly pr-3 vhtmlTag vueHtmlParent mt-3 mb-0" v-html="getResizedText(eve.event_desc)" style="height: 90px; overflow: hidden;">
                      </p>
                    </div>
                  </div>
                  <b-btn class="w-100 mr-2 mt-3 iq-bg-dark rsvpBtn" v-if="allEventActive" :key="eve.event_rsvp_added"  size="md" style="border: none;" @click="goToEveView(eve.event_id)">
                    {{eve.event_weblink && eve.event_weblink.includes('thankyou') ? "Registered" : "View"}}
                    <!-- {{eve.event_rsvp_added && eve.event_rsvp_added.length && !eve.event_weblink.includes('thankyou') ? (eve.event_hosting_platform == eventHostingPlatformConfig.ZOOM ? 'Click here to join' : "Click Here to Continue") : paymentProcessed ? "Click Here to Continue" : 'Register' }} -->
                  </b-btn>
                </b-card-body>
              </iq-card>
            </b-col>
            </div>
          </template>
          <template v-else>
            <b-col>
              {{isLoading ? 'Loading ....' : 'No Event Found'}}
            </b-col>
          </template>
          <b-row class="w-100">
            <b-col size="12">
              <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="!allEventLoaded && allEventActive || !myEventLoaded && myEventActive || pastEventActive && !pastEventLoaded">
                <button type="button" class="btn btn-primary" @click="loadMore()">
                  Load More
                </button>
              </div>
            </b-col>
          </b-row>

          <b-toast
            v-model="showToast"
            name="Toast"
            :variant="toastVariant"
            :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>

          <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()"/>

          <template v-if="showPaymentModal && selectedEventId">
            <b-modal v-model="showPaymentModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
              <Paygw :propModuleType="modName" :propModuleName="selectedEventName" :propModuleObjId="selectedEventId" @emitPaymentStatus="emitPaymentStatus" :propEventMktSrc="source"/>
              <template #modal-footer="">
                <b-button @click="showPaymentModal = false" size="sm" class="pull-left">
                  Close
                </b-button>
              </template>
            </b-modal>
          </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped>
  @media (max-width: 485px) {
    .eventBannerContainer .mobileViewEvent {
      padding: 0px !important;
      text-align: end;
      .DontShow {
        display: none;
      }
      .social-info {
        margin-top: -28px !important;
        padding: 13px;
      }
    }
    .mobile_row_size{
      margin: 0px !important;
    }
  }
  .pastDate {
    .rsvpCardBody .rsvpBtn {
      background: green !important;
      color: #fff !important;
    }
  }
  .image {
    width: 100%;
    height: 200px;
    max-width: 100%;
  }
  .weblinkPara {
    height: 30px;
    overflow: hidden;
    padding-right: 26px;
  }
  .event-images .image.image_eventpost {
    width: 100%;
    height: auto !important;;
    min-height: 254px;
  }
 .rating_comments{
    justify-content: right;
    right: 10px;
    top:-5px;
  }
  @media (max-width : 767px){
    .mobileNoPadding{
      margin: auto;
    }
  }
  @media (min-width : 767px){
    .rating_comments{
      top:0px;
    }
  }

  .showFlexListDiv{
    background-color: transparent;
    .showFlexList{
      background-color: #fff;
    }
  }
  .profile-page{
    background-color: #fff;
  }
  .iq-card-height{
    background-color: #fff !important;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events"
import moment from "moment"
import Utility from "../../../Utils/utility"
import AlertBox from "../../../components/AlertBox.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import eventHostingPlatformConfig from "../../../FackApi/json/EventHostingPlatforms.js"
import Paygw from "../../../views/Gide/Paygw/Paygw.vue"
import userRolesJson from "../../../FackApi/json/UserRoles.json"
import IqCard from "../../../components/socialvue/cards/iq-card.vue"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "Events",
  props: {
    propShowUserEvent: {
      default: false
    },
    propUserId: {
      default: null
    }
  },
  components: {
    AlertBox,
    Paygw,
    IqCard
  },
  data () {
    return {
      soicalInfo: [
        {
          name: "My Event"
        },
        {
          name: "All Events"
        }
      ],
      eveImgObj: {},
      eventListArr: [],
      propShowPBAModal: false,
      propPBAId: null,
      rsvpUserListObj: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Events",
      vmAllEventCount: 0,
      vmMyEventCount: 0,
      myEventActive: false,
      pastEventActive: false,
      allEventActive: true,
      vmBannerImg: Utility.getBgImageForLetter(this, "E"),
      eventListObj: {
        myEvents: [],
        allEvents: [],
        pastEvents: []
      },
      eventSearchListObj: {
        myEvents: [],
        allEvents: [],
        pastEvents: []
      },
      vmEventSearch: null,
      allEventLoaded: false,
      myEventLoaded: false,
      pastEventLoaded: false,
      limit: 12,
      allEventOffset: 0,
      pastEventOffset: 0,
      myEventOffset: 0,
      searchOffset: 0,
      currentUserId: null,
      showUpgradeAlertBox: false,
      alertDesc: "Please upgrade to Gide Plus",
      alertTitle: "Events",
      okText: "Upgrade",
      cancelText: "Not now",
      isLoading: false,
      eventHostingPlatformConfig: eventHostingPlatformConfig,
      showPaymentModal: false,
      modName: "event", // Module name
      selectedEventId: null,
      selectedEventName: null,
      paymentProcessed: false,
      userRolesJson: userRolesJson,
      source: "ONLINE"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    }
  },
  mounted () {
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)
    // if sourceData is available then store in source else take user defined value to store in source
    this.source = this.$route.query.source ? this.$route.query.source : this.sourceData
    // Called on Gide User Interfacing Menu Item Events with Tabs:- Upcoming, Past, My Events

    if (this.propShowUserEvent) {
      this.currentUserId = this.propUserId
      this.switchTabs("myEvent")
    }
    else {
      this.currentUserId = this.userData.user_id
      this.loadRequiredData()
    }
  },
  methods: {
    /**
     * userPermission
     */
    userPermission (actionName) {
      return userPermission(this, this.userData, actionName)
    },
    /**
     * loadMore
     */
    loadMore () {
      const loadMore = true
      if (
        (this.allEventActive && !this.allEventLoaded) || (this.pastEventActive && !this.pastEventLoaded)
      ) {
        this.eventList(this.vmEventSearch, loadMore)
      }
      else if (this.myEventActive && !this.myEventLoaded) {
        this.eventRSVPList(this.vmEventSearch, loadMore)
      }
    },
    /**
     * emitPaymentStatus
    */
    emitPaymentStatus (event) {
      this.selectedEventId = null
      this.selectedEventName = null
      this.showPaymentModal = false
      this.paymentProcessed = true
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.eventList()
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (datetime, result = "normal") {
      try {
        if (result === "month") {
          return moment(datetime).format("MMM")
        }
        else if (result === "date") {
          return moment(datetime).format("DD")
        }
        else {
          return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
        }
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * eventAdd
     */
    eventAdd () {
      this.$router.push("/event_add")
    },
    /**
     * goToEventDashboard
     */
    goToEventDashboard () {
      this.$router.push("/event_list")
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getResizedText
     */
    getResizedText (text) {
      return text ? text.substr(0, 200) : ""
    },
    /**
     * goToEveView
     */
    goToEveView (eveId) {
      this.$router.push("/event/" + eveId)
    },
    /**
     * goToEventPlanList
     */
    goToEventPlanList () {
      this.$router.push("/subscription_plans")
    },
    /**
     * switchTabs
     */
    switchTabs (type) {
      this.vmEventSearch = null
      this.allEventOffset = 0
      this.pastEventOffset = 0
      this.myEventOffset = 0
      this.allEventLoaded = false
      this.myEventLoaded = false
      this.pastEventLoaded = false
      switch (type) {
        case "myEvent":
          this.myEventActive = true
          this.allEventActive = false
          this.pastEventActive = false
          if (this.eventListObj.myEvents.length) {
            this.eventListArr = this.eventListObj.myEvents
            this.myEventOffset = this.eventListObj.myEvents.length
          }
          else {
            this.eventListArr = []
            this.eventRSVPList()
          }
          break
        case "pastEvent":
          this.pastEventActive = true
          this.allEventActive = false
          this.myEventActive = false
          if (this.eventListObj.pastEvents.length) {
            this.eventListArr = this.eventListObj.pastEvents
            this.pastEventOffset = this.eventListObj.pastEvents.length
          }
          else {
            this.eventListArr = []
            this.eventList()
          }
          break
        default:
          this.myEventActive = false
          this.allEventActive = true
          this.pastEventActive = false
          if (this.eventListObj.allEvents.length) {
            this.eventListArr = this.eventListObj.allEvents
            this.allEventOffset = this.eventListObj.allEvents.length
          }
          else {
            this.eventListArr = []
            this.eventList()
          }
          break
      }
    },
    /**
     * searchEvent
     */
    searchEvent (vmEventSearch) {
      if (vmEventSearch && vmEventSearch.length) {
        this.myEventOffset = 0
        this.allEventOffset = 0
        this.pastEventOffset = 0
        this.eventSearchListObj = {
          myEvents: [],
          allEvents: [],
          pastEvents: []
        }
        this.eventListArr = []
        this.allEventLoaded = false
        this.pastEventLoaded = false
        this.myEventLoaded = false
        if (this.myEventActive) {
          this.eventRSVPList(vmEventSearch)
        }
        else {
          this.eventList(vmEventSearch)
        }
      }
      else {
        let type = this.myEventActive ? "myEvent" : this.pastEventActive ? "pastEvent" : "allEvent"
        this.switchTabs(type)
      }
    },
    /*
     * eventList
     * */
    async eventList (vmEventSearch = null, loadMore = false) {
      try {
        let filter = {
          "user_role": this.userData.user_role,
          limit: this.limit,
          offset: this.allEventOffset,
          upcoming_events: true
        }

        let search = false
        if (vmEventSearch && vmEventSearch.length) {
          search = true
          filter.search = vmEventSearch
        }

        if (this.pastEventActive) {
          filter.upcoming_events = false
          filter.past_events = true
          filter.offset = this.pastEventOffset
        }

        this.isLoading = true
        let eveListResp = await events.eventList(this, filter)
        this.isLoading = false
        if (eveListResp && eveListResp.resp_status) {
          /** Search */
          if (search) {
            if (loadMore) {
              if (this.pastEventActive) {
                this.eventSearchListObj["pastEvents"] = [ ...this.eventSearchListObj["pastEvents"], ...eveListResp.resp_data.data ]
              }
              else {
                this.eventSearchListObj["allEvents"] = [ ...this.eventSearchListObj["allEvents"], ...eveListResp.resp_data.data ]
              }
            }
            else {
              if (this.pastEventActive) {
                this.eventSearchListObj["pastEvents"] = eveListResp.resp_data.data
              }
              else {
                this.eventSearchListObj["allEvents"] = eveListResp.resp_data.data
              }
            }

            if (this.pastEventActive) {
              this.eventListArr = [ ...this.eventSearchListObj["pastEvents"] ]
            }
            else {
              this.eventListArr = [ ...this.eventSearchListObj["allEvents"] ]
            }
          }
          else {
            /** Without Search */
            if (loadMore) {
              if (this.pastEventActive) {
                this.eventListObj["pastEvents"] = [ ...this.eventListObj["pastEvents"], ...eveListResp.resp_data.data ]
              }
              else {
                this.eventListObj["allEvents"] = [ ...this.eventListObj["allEvents"], ...eveListResp.resp_data.data ]
              }
            }
            else {
              this.allEventLoaded = false
              this.pastEventLoaded = false

              if (this.pastEventActive) {
                this.eventListObj["pastEvents"] = eveListResp.resp_data.data
              }
              else {
                this.eventListObj["allEvents"] = eveListResp.resp_data.data
              }
            }

            if (this.pastEventActive) {
              this.eventListArr = [ ...this.eventListObj["pastEvents"] ]
            }
            else {
              this.eventListArr = [ ...this.eventListObj["allEvents"] ]
            }
          }

          this.eveImgObj = { ...this.eveImgObj, ...eveListResp.event_images }
          this.rsvpUserListObj = { ...this.rsvpUserListObj, ...eveListResp.rsvp_users }

          if (this.pastEventActive) {
            this.pastEventOffset += this.limit
          }
          else {
            this.allEventOffset = this.allEventOffset + this.limit
          }
        }
        else {
          if (search && !loadMore) {
            // If user is searching and not clicked on load more then empty the lise
            if (this.eventSearchListObj["allEvents"].length) {
              this.eventSearchListObj["allEvents"] = []
            }
            else if (this.eventSearchListObj["pastEvents"].length) {
              this.eventSearchListObj["pastEvents"] = []
            }
            this.eventListArr = []
          }
          else {
            if (this.pastEventActive) {
              this.pastEventLoaded = true
            }
            else {
              this.allEventLoaded = true
            }
          }
          this.allEventLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
    },
    /**
    * eventRSVPList
    */
    async eventRSVPList (vmEventSearch = null, loadMore = false) {
      let filter = {
        curr_user_id: this.currentUserId,
        limit: this.limit,
        offset: this.myEventOffset
      }

      let search = false
      if (vmEventSearch && vmEventSearch.length) {
        search = true
        filter.search = vmEventSearch
      }

      this.isLoading = true
      const eventRsvpResp = await events.eventRsvpMyEventList(this, filter)
      this.isLoading = false
      if (eventRsvpResp.resp_status) {
        if (search) {
          if (loadMore) {
            this.eventSearchListObj["myEvents"] = [ ...this.eventSearchListObj["myEvents"], ...eventRsvpResp.resp_data.data ]
          }
          else {
            this.eventSearchListObj["myEvents"] = eventRsvpResp.resp_data.data
          }
          this.eventListArr = [ ...this.eventSearchListObj["myEvents"] ]
        }
        else {
          if (loadMore) {
            this.eventListObj["myEvents"] = [ ...this.eventListObj["myEvents"], ...eventRsvpResp.resp_data.data ]
          }
          else {
            this.eventListObj["myEvents"] = eventRsvpResp.resp_data.data
            this.myEventOffset = 0
            this.myEventLoaded = false
          }
          this.eventListArr = [ ...this.eventListObj["myEvents"] ]
        }
        this.eveImgObj = { ...this.eveImgObj, ...eventRsvpResp.event_images }
        this.myEventOffset = this.myEventOffset + this.limit
      }
      else {
        if (search && !this.eventSearchListObj["myEvents"].length) {
          this.eventListArr = []
        }
        else if (!this.eventListObj["myEvents"].length) {
          this.eventListArr = []
        }
        this.myEventLoaded = true
      }
    },
    /**
    * setRsvpForUser
    */
    async setRsvpForUser (eve) {
      if (eve.event_rsvp_added) {
        if (eve.event_weblink) {
          window.open(eve.event_weblink, "_blank")
        }
        return
      }
      this.cvLoadingStatus = true
      try {
        let payload = {
          module_obj_id: this.userData.user_id,
          module_name: "STUDENT",
          event_id: eve.event_id,
          event_name: eve.event_name,
          event_is_paid: eve.event_is_paid === 0 ? "0" : eve.event_is_paid,
          user_email: this.userData.user_email,
          user_mobile: this.userData.user_mobile,
          event_mkt_src: "GIDEAPP"
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
        ) {
          // @todo shubham If More then one organisation then show model to select org
          // Org
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organizations && Object.keys(this.userData.modules.organizations).length > 0
        ) {
          payload.module_obj_id = Object.keys(this.userData.modules.organizations)[0]
          payload.module_name = "ORG"
        }

        let eveRsvpResp = await events.eventRsvpAdd(this, payload)

        if (!eveRsvpResp.resp_status && eveRsvpResp.resp_code === "ERR_USER_NOT_SUBSCRIBED") {
          this.showUpgradeAlertBox = true
        }
        else if (!eveRsvpResp.resp_status && eveRsvpResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED") {
          this.selectedEventId = eve.event_id
          this.selectedEventName = eve.event_name

          ApiResponse.responseMessageDisplay(this, eveRsvpResp)
          const options = {
            title: "Activity Purchase",
            size: "md",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "Pay",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          }
          let message = "This is a paid activity. Kindly click on the 'Pay' button to continue"
          const value = await this.$bvModal.msgBoxConfirm(message, options)
          if (value) {
            this.showPaymentModal = true
          }
        }
        else if (!eveRsvpResp.resp_status && eveRsvpResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER") {
          const selectedPlanForModule = {
            module_obj_id: eve.event_id,
            module: "event",
            module_obj_name: eve.event_name
          }

          await this.$store.commit("Paygw/addSelectedModuleForPlanCommit", selectedPlanForModule)
          this.$router.push("/subscription_plans?type=custom")
        }
        else if (!eveRsvpResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, eveRsvpResp)
        }
        else if (eveRsvpResp.resp_status) {
          const findIndex = this.eventListObj.allEvents.findIndex(ele => ele.event_id === eve.event_id)
          this.eventListObj.allEvents[findIndex] = { event_rsvp_added: "true", ...eveRsvpResp.resp_data }
          this.eventListArr = [ ...this.eventListObj.allEvents ]
          this.eventListObj.myEvents.unshift(this.eventListObj.allEvents[findIndex])
          if (eveRsvpResp && eveRsvpResp.resp_status) {
            this.eventListObj.allEvents[findIndex].event_weblink = eveRsvpResp.resp_data.event_weblink
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at setRsvpForUser() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
    * pastDateCheck
    * */
    pastDateCheck (date) {
      if (new Date(date).getTime() < new Date().getTime()) {
        return false
      }
      else {
        return true
      }
    },
    /**
     * goToCreateEve
     */
    goToCreateEve () {
      this.$router.push("/event_add")
    }
  }
}
</script>

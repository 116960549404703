<template lang="">
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <b-card no-body>
              <b-tabs class="history_tabs" pills card vertical end>
                <b-tab
                  v-for="(tab, index) in userDocHIstoryList"
                  :key="index+1"
                  :active="index === 0">
                  <template #title>
                    <div>
                    <span style="display:flex; position:relative"><b>{{ tab.modified_on | dateFormatDDMMYYYY }}</b>
                      <i style="position: absolute; right:0; top:6px" class="fa-solid fa-trash-can-arrow-up pull-right primary-color" title="Restore" @click="userYjsDocsHistoryAdd(tab.yjsdoc)"></i>
                    </span>
                    <small>{{ tab.user_name }}</small>
                    </div>
                  </template>
                  <b-card-text class="p-2">{{ tab.yjsdoc }}</b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
        </b-col>
      </b-row>
      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>
    </b-container>
  </template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { userYjsdocsHistorys } from "../../../FackApi/api/userYjsdocsHistory.js"
import { Spcms } from "../../../FackApi/api/Spcm.js"

export default {
  name: "YjsDocHistoryList",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propDocHistory: {
      type: Object,
      default: null
    }
  },
  async mounted () {
    socialvue.index()
    // await this.StudentCounselorTeamList()
    await this.userYjsDocsHistoryList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      editorContent: "",
      toastTitle: "Editor",
      showToast: false,
      toastMsg: "",
      toastVariant: "default",
      provider: null,
      cvSubmitAddBtn: "Save",
      cvSubmitForReviewBtn: "Get It Reviewed",
      cvReviewedAndCommentedBtn: "Reviewed & Commented",
      cvReviewedAndClosedBtn: "Reviewed & Closed",
      vmuserYjsdocsHistoryFormData: {},
      cvSubmitAddAndCloseBtn: "Save & Close",
      student_counselor_team: "",
      whereFilter: {},
      yjsdocs_id: "",
      user_id: "",
      stuConselorTeamObjList: [],
      userDocHIstoryList: []
    }
  },
  components: {
    // VueEditor
  },
  methods: {
    async userYjsDocsHistoryAdd (editorContent) {
      try {
        this.$emit("emitUserYjsDocsHistory", editorContent)
      }
      catch (err) {
        console.error("Exception occurred at userYjsDocsHistoryEdit() and Exception:", err.message)
      }
    },
    /**
     * userYjsDocsHistoryList
    */
    async userYjsDocsHistoryList () {
      try {
        this.whereFilter.yjsdocs_id = this.propDocHistory.yjsdocs_id
        let userYjsDocsHistoryListResp = await userYjsdocsHistorys.userYjsDocsHistoryList(this, this.whereFilter)
        if (userYjsDocsHistoryListResp.resp_status) {
          this.userDocHIstoryList = userYjsDocsHistoryListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception in organisationList and err: ", err.message)
      }
    },
    /**
     * Student Counselor Team List
     */
    async StudentCounselorTeamList () {
      try {
        if (this.propDocHistory) {
          // this.propDocHistory.yjsdocs_collaborators = this.propDocHistory.yjsdocs_collaborators // Lead Counselor with double ll
          this.whereFilter.student_counselor_team = this.propDocHistory.yjsdocs_collaborators
          this.whereFilter.spcm_id = this.propDocHistory.module_object_id
        }
        else {
          this.whereFilter = null
        }
        let studentCounselorTeamResp = await Spcms.StudentCounselorTeamList(this, this.whereFilter)
        if (studentCounselorTeamResp.resp_status) {
          this.stuConselorTeamObjList = studentCounselorTeamResp.resp_data.data
        }
        else {
          /*
          // No need to show error if counsellor team not found
          this.toastMsg = studentCounselorTeamResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          */
        }
      }
      catch (err) {
        console.error("Exception occurred at StudentCounselorTeamList() and Exception:", err.message)
      }
    }
  }
}
</script>
<style scoped>
  .tab-content{
    -webkit-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1) !important;
    box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1) !important;
  }
</style>

/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let YjsDocs = {
  /**
   * yjsDocsList
   */
  async yjsDocsList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "yjsdocs_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at yjsDocsList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * yjsDocsView
   */
  async yjsDocsView (context, docsId) {
    try {      
      let post_data = new FormData();
      post_data.append('yjsdocs_id', docsId);
      return await request.curl(context, "yjsdocs_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at yjsDocsView() and Exception:",err.message)
    }    
  },

  /**
   * yjsDocsAdd
   */
  async yjsDocsAdd (context, docsObj) {
    try{
      let post_data = new FormData();
    
      for (let key in docsObj) {
        post_data.append(key, docsObj[key]);
      }

      return await request.curl(context, "yjsdocs_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at yjsDocsAdd() and Exception:',err.message)
    }
  },

  /**
   * yjsDocsEdit
   */
  async yjsDocsEdit (context, docsObj) {
    try{
      let post_data = new FormData();
    
      for (let key in docsObj) {
        post_data.append(key, docsObj[key]);
      }

      return await request.curl(context, "yjsdocs_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at yjsDocsEdit() and Exception:',err.message)
    }
  },

  /**
   * yjsDocsDelete
   */
  async yjsDocsDelete (context, docsId) {
    try{
      let post_data = new FormData();
      
      post_data.append('yjsdocs_id', docsId);

      return await request.curl(context, "yjsdocs_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at yjsDocsDelete() and Exception:',err.message)
    }
  },

  /**
   * yjsDocsEditState
   */
  async yjsDocsEditState (context, docsObj) {
    try{
      let post_data = new FormData();
    
      for (let key in docsObj) {
        post_data.append(key, docsObj[key]);
      }

      return await request.curl(context, "yjsdocs_edit_state", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at yjsDocsEditState() and Exception:',err.message)
    }
  },

  /**
   * yjsDocsEditState
   */
  async yjsDocsEnhance (context, docsObj) {
    try{
      let post_data = new FormData();
    
      for (let key in docsObj) {
        if (docsObj[key] && typeof docsObj[key] != "number") {
          post_data.append(key, docsObj[key]);
        }
        else if (typeof docsObj[key] == "number") {
          post_data.append(key, docsObj[key]);
        }
      }

      return await request.curl(context, "yjsdocs_enhance", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at yjsDocsEnhance() and Exception:',err.message)
    }
  }
}

export {
  YjsDocs
}

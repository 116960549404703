<template lang="">
  <b-container fluid id="DocEdit">
    <b-row>
      <b-col col-12 class="mb-1">
        <h5>
          Document Editor
        </h5><br>
        <h6>
          Document Title:&nbsp;<span class="primary-color">{{ this.propYJSDocObj.yjsdocs_name }}</span>
          <i @click="toggleCustomUserPromptInptBox()" v-if="VUE_APP_ENV != 'prod'" class="fa fa-magic pointer ml-1 font-size-30 float-right pr-3 primary-color" title="GIDE AI Analyzer" aria-hidden="true"></i>
          <i class="fa fa-history pointer ml-1 font-size-30 float-right pr-3 primary-color" title="Document List" @click="openYjsDocHistryListModal()"></i>
        </h6>
        <h6>Document Type:&nbsp;<span class="primary-color">{{ this.propYJSDocObj.yjsdocs_code }}</span></h6>
      </b-col>
      <b-col md="12">
        <iq-card>
          <div v-if="showCustomUserPromptInptBox">
            <b>Enter your prompt below:</b>
            <textarea
              v-model="userPrompt"
              autofocus
              type="text"
              class="form-control textarea mb-4 mt-2"
              style="height: 5vh !important"
              required
            />
            <button type="button" class="btn btn-primary pull-right mb-2" @click="analyzeViaAI()">Analyze</button>
          </div>

          <!-- easys with min max length -->
          <textarea
            v-if="this.propYJSDocObj.yjsdocs_code === 'UNIV_ESSAY' || this.propYJSDocObj.yjsdocs_code === 'COMMONAPP_ESSAY'"
            v-model="editorContent"
            type="text"
            class="form-control textarea"
            :minlength="propYJSDocObj.uexcc_min_length"
            :maxlength="propYJSDocObj.uexcc_min_length"
            style="height: 40vh !important"
            required
          />
          <!-- Other editor box without min max length -->
          <textarea
            v-else
            v-model="editorContent"
            type="text"
            class="form-control textarea"
            style="height: 40vh !important"
            required
          />
          <div v-if="propYJSDocObj.yjsdocs_ai" style="color:red;position:relative" class="ai-desc mt-2">
            <b>GIDE.AI Analysis:&nbsp;</b>
            {{propYJSDocObj.yjsdocs_ai}}
          </div>
        </iq-card>
      </b-col>
      <b-col md="12">
        <div>
          <div class="pull-left">
            <div style="display:inline-flex">
              <h6>
                Params:&nbsp;<span>{{propYJSDocObj.yjsdocs_params ? propYJSDocObj.yjsdocs_params : " -- "}}</span>
              </h6>
            </div><br/>
            <div style="display:inline-flex">
              <h6>
                Tags:&nbsp;<span>{{propYJSDocObj.yjsdocs_tags ? propYJSDocObj.yjsdocs_tags : " -- "}}</span>
              </h6>
            </div>
            <div class="mb-3">
              <h6>
                Counseling Team:&nbsp;
                <span v-if="stuConselorTeamObjList && stuConselorTeamObjList.length > 0">
                  <span class="ml-3 mr-2" style="display: inline-block;" v-for="(item) in stuConselorTeamObjList" :key="item+'program'">
                    <span style="display: list-item">{{item.user_name}}</span>
                  </span>
                </span>
                <span v-else> -- </span>
              </h6>
            </div>
          </div>
          <div class="pull-right">
            <div style="display:inline-flex">
            <b-badge class="text-wrap w-125px pull-right mb-2" :style="getDocStateColorCode(propYJSDocObj.yjsdocs_state)">
              {{propYJSDocObj.yjsdocs_state_desc}}
            </b-badge>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <div class="form-group">
          <button type="button" class="btn btn-primary mr-2 mb-1" @click="userYjsDocsHistoryAdd(editorContent)">{{ cvSubmitAddBtn }}</button>

          <!-- Document State Toggle Buttons -->
          <button type="button" class="btn btn-primary mr-2 mb-1" v-if="userData.user_role == 'USERROLE11114'" @click="changeDocStatus('YJS_DOC_SUBMIT_REVIEW')">{{ cvSubmitForReviewBtn }}</button>
          <button type="button" class="btn btn-primary mr-2 mb-1" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" @click="changeDocStatus('YJS_DOC_REVIEW_COMMENTS')">{{ cvReviewedAndCommentedBtn }}</button>
          <button type="button" class="btn btn-primary mr-2 mb-1" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" @click="changeDocStatus('YJS_DOC_REVIEW_CLOSED')">{{ cvReviewedAndClosedBtn }}</button>
          <!-- Document State Toggle Buttons -->

          <button type="button" class="btn btn-primary float-right" @click="userYjsDocsHistoryAddWithSave(editorContent)">{{ cvSubmitAddAndCloseBtn }}</button>
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-model="showDocHistoryModel"
      scrollable
      :title="cvDocHistoryListHeader"
      size="xxl"
      hide-footer
    >
      <yjsDocHistoryList :propOpenedInModal="true" :propDocHistory = propYJSDocObj @emitUserYjsDocsHistory = getHistoryContent />
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
// import { VueEditor } from "vue2-editor"
import { socialvue } from "../../../config/pluginInit"
import * as Y from "yjs"
import { socket } from "../../../main.js"
import { YJSDocsCollaborate } from "../../../Utils/yjsDocsCollaborate.js"
import { YjsDocs } from "../../../FackApi/api/YjsDocs.js"
import { userYjsdocsHistorys } from "../../../FackApi/api/userYjsdocsHistory.js"
import { Spcms } from "../../../FackApi/api/Spcm.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import config from "../../../Utils/config.js"
import yjsDocHistoryList from "../YjsDocs/yjsDocHistoryList.vue"

export default {
  name: "SpcmEssayEditor",
  created () {
    // Get the document from the database
    this.documentView()

    // set the component's name inside refresh component object, because this component is about to recieve realtime updates
    if (!this.refreshComponentObj || !Object.keys(this.refreshComponentObj).includes("SpcmEssayEditor")) {
      let obj = null
      if (!this.refreshComponentObj) {
        obj = { "SpcmEssayEditor": 0 }
      }
      else {
        obj = this.refreshComponentObj
        obj["SpcmEssayEditor"] = 0
      }
      this.$store.dispatch("RefreshComponentObj/setRefreshComponentObj", obj)
    }
  },
  beforeDestroy () {
    this.provider.yjsdocs.destroy()
    this.ydoc.destroy()
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propYJSDocObj: {
      type: Object,
      default: null
    }
  },
  beforeMount () {
    let allowedEmailDomains = process.env.VUE_APP_TRUSTED_EMAIL_DOMAINS.split(",")
    for (let emailDomain of allowedEmailDomains) {
      if (this.userData.user_email.includes(emailDomain)) {
        this.VUE_APP_ENV = "local"
      }
    }
  },
  mounted () {
    socialvue.index()
    this.StudentCounselorTeamList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    refreshComponentObj () {
      return this.$store.getters["RefreshComponentObj/refreshComponentObj"]
    }
  },
  data () {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      docStatusCodeObj: {
        "YJS_DOC_DOC_WR_OPEN": "Document is being written",
        "YJS_DOC_SUBMIT_REVIEW": "Document Submitted For Review",
        "YJS_DOC_REVIEW_COMMENTS": "Document Reviewed And Commented",
        "YJS_DOC_REVIEW_CLOSED": "Document Reviewed And Approved"
      },
      editorContent: "",
      ytext: null,
      ydoc: null,
      toastTitle: "Editor",
      showToast: false,
      toastMsg: "",
      toastVariant: "default",
      provider: null,
      cvSubmitAddBtn: "Save",
      cvSubmitForReviewBtn: "Get It Reviewed",
      cvReviewedAndCommentedBtn: "Reviewed & Commented",
      cvReviewedAndClosedBtn: "Reviewed & Closed",
      vmuserYjsdocsHistoryFormData: {},
      cvSubmitAddAndCloseBtn: "Save & Close",
      student_counselor_team: "",
      whereFilter: {},
      stuConselorTeamObjList: [],
      showDocHistoryModel: false,
      cvDocHistoryListHeader: "Document History list",
      userPrompt: null,
      showCustomUserPromptInptBox: false,
      VUE_APP_ENV: process.env.VUE_APP_ENV
    }
  },
  components: {
    yjsDocHistoryList
  },
  methods: {
    /**
     * Load the Document for View & Edit
     */
    async documentView () {
      try {
        let yjsdocsData = await YjsDocs.yjsDocsView(this, this.propYJSDocObj.yjsdocs_id)
        if (yjsdocsData.resp_status) {
          // yjsdocsData = new Uint8Array(yjsdocsData.resp_data.data.yjsdocs)

          // Initialize Yjs document and provider
          this.ydoc = new Y.Doc()

          // Initialising the custom provider to transmit the changes in the Yjs document over websocket protocol
          this.provider = new YJSDocsCollaborate(this, this.ydoc, yjsdocsData.resp_data.data.yjsdocs_id, socket, this.userData.user_id)

          // Start the collaborative editing session
          socket.emit("yjsdocs_join_room_for_collaboration", yjsdocsData.resp_data.data.yjsdocs_id, this.userData.user_id)

          // create the Y.Text instance named yjs_shared_text in the yjs doc
          const ytext = this.ydoc.getText("yjs_shared_text")

          // Bind Yjs content to editorContent
          this.editorContent = ytext.toString()

          // Listen for changes from Yjs and update editorContent
          ytext.observe(event => {
            this.editorContent = ytext.toString()
          })

          // Watch for changes to editorContent and update Yjs document
          this.$watch(
            "editorContent",
            (newValue) => {
              if (ytext.toString() !== newValue) {
                ytext.delete(0, ytext.length) // Clear current Yjs content
                ytext.insert(0, newValue) // Insert new content
              }
            }
          )
        }
      }
      catch (err) {
        console.error("Exception in documentView and err: ", err.message)
      }
    },
    /**
     * Student Counselor Team List
     */
    async StudentCounselorTeamList () {
      try {
        if (this.propYJSDocObj) {
          // this.propYJSDocObj.yjsdocs_collaborators = this.propYJSDocObj.yjsdocs_collaborators // Lead Counselor with double ll
          this.whereFilter.student_counselor_team = this.propYJSDocObj.yjsdocs_collaborators
          this.whereFilter.spcm_id = this.propYJSDocObj.module_object_id
        }
        else {
          this.whereFilter = null
        }
        let studentCounselorTeamResp = await Spcms.StudentCounselorTeamList(this, this.whereFilter)
        if (studentCounselorTeamResp.resp_status) {
          this.stuConselorTeamObjList = studentCounselorTeamResp.resp_data.data
        }
        else {
          /*
          // No need to show error if counsellor team not found
          this.toastMsg = studentCounselorTeamResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          */
        }
      }
      catch (err) {
        console.error("Exception occurred at StudentCounselorTeamList() and Exception:", err.message)
      }
    },
    async userYjsDocsHistoryAdd (editorContent) {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.vmuserYjsdocsHistoryFormData.yjsdoc_id = this.propYJSDocObj.yjsdocs_id
        this.vmuserYjsdocsHistoryFormData.module_object_id = this.propYJSDocObj.module_object_id
        this.vmuserYjsdocsHistoryFormData.yjsdoc = editorContent

        let userYjsDocsHistoryAddResp = await userYjsdocsHistorys.userYjsDocsHistoryAdd(this, this.vmuserYjsdocsHistoryFormData)
        if (userYjsDocsHistoryAddResp && !userYjsDocsHistoryAddResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at userYjsDocsHistoryEdit() and Exception:", err.message)
      }
    },
    userYjsDocsHistoryAddWithSave (editorContent) {
      //  Emit if opened in Modal
      this.userYjsDocsHistoryAdd(editorContent)
      if (this.propOpenedInModal) {
        this.$emit("emitCloseuserYjsdocsHistoryAddModal", this.vmuserYjsdocsHistoryFormData)
      }
    },
    /**
     * changeDocStatus
     */
    async changeDocStatus (state) {
      try {
        const yjsDocsSateEditObj = {
          yjsdocs_id: this.propYJSDocObj.yjsdocs_id,
          yjsdocs_state: state,
          yjsdocs_state_desc: this.docStatusCodeObj[state]
        }
        const yjsEditDocStateResp = await YjsDocs.yjsDocsEditState(this, yjsDocsSateEditObj)
        ApiResponse.responseMessageDisplay(this, yjsEditDocStateResp)
        if (yjsEditDocStateResp.resp_status) {
          this.propYJSDocObj.yjsdocs_state = state
          this.propYJSDocObj.yjsdocs_state_desc = this.docStatusCodeObj[state]
        }
      }
      catch (err) {
        console.error("Exception in changeDocStatus() and err: ", err.message)
      }
    },
    /**
     * getDocStateColorCode
     */
    getDocStateColorCode (state) {
      return `vertical-align: middle; color: #FFF; background: ${config.YjsDocsStateColors[state]} !important;`
    },
    /**
     * openYjsDocHistryListModal
     */
    openYjsDocHistryListModal () {
      this.showDocHistoryModel = true
    },
    /**
      * getHistoryContent
      */
    getHistoryContent (item) {
      this.editorContent = item
      this.showDocHistoryModel = false
    },
    /**
     * toggleCustomUserPromptInptBox
     */
    toggleCustomUserPromptInptBox () {
      this.showCustomUserPromptInptBox = !this.showCustomUserPromptInptBox
    },
    /**
     * analyzeViaAI
     */
    async analyzeViaAI () {
      try {
        const payload = {
          vue_component_name: "SpcmEssayEditor",
          yjsdocs_id: this.propYJSDocObj.yjsdocs_id,
          yjsdocs: this.editorContent,
          yjsdocs_code: this.propYJSDocObj.yjsdocs_code,
          module_object_id: this.propYJSDocObj.module_object_id,
          user_prompt: this.userPrompt
        }

        const enhanceResp = await YjsDocs.yjsDocsEnhance(this, payload)
        if (!enhanceResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, enhanceResp)
        }
      }
      catch (err) {
        console.error("Exception in analyzeViaAI() and err: ", err.message)
      }
    }
  },
  watch: {
    refreshComponentObj: function (newVal) {
      if (newVal && newVal["SpcmEssayEditor"] != 0) {
        this.$emit("emitRefreshYjsDoc", this.propYJSDocObj.yjsdocs_id)
      }
    }
  }
}
</script>
<style lang="scss">
  .ai-desc {
    background-color: whitesmoke;
    padding: 15px;
  }
</style>
